
import LazyHydrate from 'vue-lazy-hydration'

export default {
    name: 'Home',

    components: { LazyHydrate },

    head: {
        EncontreApoiometa: [
            {
                hid: 'description',
                name: 'description',
                content:
                    'Conectamos mulheres a redes de enfrentamento a violência doméstica e violência sexual no Brasil, como aplicativo maria da penha, delegacia da mulher, medida protetiva online, atendimento por whatsapp na quarentena, e casa abrigo.',
            },
            {
                name: 'keywords',
                content:
                    'enfrentamento a violencia domestica, violencia sexual, aplicativo maria da penha, medida protetiva online, atendimento por whatsapp na quarentena, casa abrigo, juizado da mulher, centro da mulher, direitos das mulheres',
            },
        ],
    },
}
