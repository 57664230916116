import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5ea3b269&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=5ea3b269&prod&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EncontreApoio: require('/tmp/build_63b4a9ca/components/servicos/EncontreApoio.vue').default,CardsAtalhosHome: require('/tmp/build_63b4a9ca/components/core/CardsAtalhosHome.vue').default,CardsAtalhosInterna: require('/tmp/build_63b4a9ca/components/core/CardsAtalhosInterna.vue').default})
