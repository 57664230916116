
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'EncontreApoio',

    data: () => ({
        filtros: {
            input_usuario: '',
            estado: '',
            atendimento: '',
            tipo: [],
        },

        tipos: [
            {
                slug: 'denuncia',
                nome: 'Denúncia',
                rota: { name: 'ajuda-categoria-denuncia' },
            },
            {
                slug: 'acolhimento',
                nome: 'Acolhimento',
                rota: { name: 'ajuda-categoria-acolhimento' },
            },
            {
                slug: 'psicologico',
                nome: 'Psicológico',
                rota: { name: 'ajuda-categoria-psicologico' },
            },
            {
                slug: 'juridico',
                nome: 'Jurídico',
                rota: { name: 'ajuda-categoria-juridico' },
            },
            {
                slug: 'saude',
                nome: 'Saúde',
                rota: { name: 'ajuda-categoria-saude' },
            },
            {
                slug: 'delegacia',
                nome: 'Delegacia da Mulher',
                rota: {
                    name: 'ajuda-todos-os-estados',
                    query: {
                        input_usuario: 'delegacia',
                        tipo: 'denuncia,acolhimento,psicologico,juridico,saude',
                    },
                },
            },
            {
                slug: 'medida-protetiva',
                nome: 'Medida Protetiva Online',
                rota: {
                    name: 'ajuda-todos-os-estados',
                    query: {
                        tipo: 'medida',
                    },
                },
            },
            {
                slug: 'whatsapp',
                nome: 'Whatsapp',
                rota: { name: 'ajuda-canal-whatsapp' },
            },
        ],

        carouselImgs: [
            {
                src: '/img/mulheres_seguras_violencia_domestica_sexual_plataforma_unfpa_onu.jpg',
                link: '',
            },
        ],
    }),

    computed: {
        serializedFiltros() {
            const params = {}
            for (const filtro in this.filtros) {
                if (this.filtros[filtro].length) {
                    if (Array.isArray(this.filtros[filtro])) {
                        params[filtro] = this.filtros[filtro].join(',')
                    } else {
                        params[filtro] = this.filtros[filtro]
                    }
                }
            }
            return params
        },

        ...mapState('servico', ['estados']),
        ...mapGetters('servico', ['listaEstados']),
    },

    methods: {
        buscarServicos() {
            let path = this.$route.path
            if (!this.filtros.estado || this.filtros.estado === '') {
                path = '/preciso-de-ajuda/todos-os-estados'
            } else if (this.filtros.estado === 'nacional') {
                path = '/preciso-de-ajuda/nacional/brasil-br'
            } else {
                path = '/preciso-de-ajuda' + this.estados[this.filtros.estado].rota
            }

            this.$router.push({ path, query: this.serializedFiltros })
        },
    },
}
