
export default {
    name: 'CardAtalho',

    props: {
        imgSrc: {
            type: String,
            required: true,
        },
        imgAlt: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        to: {
            type: Object,
            required: true,
        },
        ariaLabel: {
            type: String,
            required: true,
        },
    },
}
